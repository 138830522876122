// blog style start hear

.blog-single-one{
    .thumbnail{
        padding: 0 10px;
        background: linear-gradient(0deg, #F7F7F7 80%, #00000000 80%);
        display: block;
        .inner{
            overflow: hidden;
            display: block;
            img{
                width: 100%;
                transition: .6s;
            }
            &:hover{
                img{
                    transform: scale(1.15);
                }
            }
        }
    }
    .head{
        display: flex;
        align-items: center;
        padding: 20px;
        border-left: 10px solid var(--color-primary);
        border-right: 10px solid var(--color-primary);
        .single-info{
            display: flex;
            align-items: center;
            i{
                color: var(--color-primary);
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
                color: var(--color-primary);
            }
            p{
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #6E777D;
                margin-left: 8px;
                margin-bottom: -2px;
                @media #{$sm-layout} {
                    font-size: 13px;
                }
            }
            &.date-area{
                margin-right: 30px;
                @media #{$sm-layout} {
                    margin-right: 10px;
                }
            }
        }   
    }
    .body{
        padding: 23px 30px 30px 30px;
        background: #F7F7F7;
        a.rts-btn.btn-border{
            border: 1px solid var(--color-primary) !important;
            background: #1F1F25;
        }
        @media #{$sm-layout} {
            padding: 15px;
        }
        a{
            .title{
                font-weight: 700;
                font-size: 24px;
                line-height: 34px;
                color: #1F1F25;
                margin-bottom: 21px;
                transition: .3s;
                @media #{$md-layout} {
                    font-size: 20px;
                    line-height: 32px;
                }
                @media #{$sm-layout} {
                    font-size: 20px;
                    line-height: 32px;
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
    }
}


.rts-cl-home-blog{
    border-radius: 30px;
    .thumbnail{
        img{
            border-radius: 30px 30px 0 0;
        }
    }
    .content-area{
        padding: 40px;
        border-radius: 0 0 30px 30px;
        background: #fff;
        @media #{$sm-layout} {
            padding: 10px;
        }
        .head{
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 17px;
            .single-info{
                display: flex;
                align-items: center;
                gap: 10px;
                p{
                    font-size: 14px;
                }
                i{
                    color: var(--color-primary);
                }
            }
        }
        .body{
            .title{
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: #1F1F25;
                transition: .3s;
                @media #{$large-mobile} {
                    font-weight: 600;
                    font-size: 20px;
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
            .rts-btn.btn-primary{
                margin-top: 15px;
            }
        }
    }
}


.index-cleaning-home{
    .air-condition-swiper-wrapper .swiper-wrapper .swiper-slide .single-case-ac .thumbnail img{
        border-radius: 30px;
    }
    .air-condition-swiper-wrapper .swiper-wrapper .swiper-slide.swiper-slide-active .inner-content p.disc {
        width: 75%;
    }
    .air-condition-swiper-wrapper .swiper-wrapper .swiper-slide .single-case-ac .inner-content {
        width: 557px;
        height: 369px;
        @media #{$large-mobile} {
            background: transparent;
            color: #fff;
            p.disc{
                color: #fff;
            }
            .title{
                color: #fff;
            }
        }
    }
}



.cpmpay-story-single-about{
    .rts-btn.btn-primary{
        display: flex;
        align-items: center;
        max-width: max-content;
        margin-top: 25px;
        i{
            margin-left: 15px;
        }
    }
}