



/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* elements */
@import'elements/header';
@import'elements/mobile-menu';
@import'elements/button';
@import'elements/nav';
@import'elements/banner';
@import'elements/swiper';
@import'elements/funfacts';
@import'elements/cta';
@import'elements/about';
@import'elements/common';
@import'elements/service';
@import'elements/projects';
@import'elements/working-process';
@import'elements/blog';
@import'elements/blog-details';
@import'elements/footer';
@import'elements/search-input';
@import'elements/side-bar';
@import'elements/team';
@import'elements/testimonials';
@import'elements/faq';
@import'elements/date-picker';
@import'elements/time-picker';
@import'elements/appoinment';
@import'elements/awesome-feedback';
@import'elements/contact';
@import'elements/back-to-top';
@import'elements/pre-loader.scss';
